import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DatePickerModule } from 'primeng/datepicker';
import { SelectModule } from 'primeng/select';
import { IftaLabelModule } from 'primeng/iftalabel';
import { OrderListModule } from 'primeng/orderlist';
import { FieldsetModule } from 'primeng/fieldset';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { TextareaModule } from 'primeng/textarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SvgComponent } from './components/svg/svg';
import { HeaderComponent } from './components/header/header';
import { FooterComponent } from './components/footer/footer';
import { DatePickerComponent } from './components/date-picker/date-picker';
import { MapComponent } from './components/map/map';
import { ClockComponent } from './components/clock/clock';
import { PipeModule } from './pipes/pipes.module';
import { DirectiveModule } from './directives/directive.module';
import { CardEventComponent } from './components/card-events/card-event';
import { ProfileCardComponent } from './components/profile-card/profile-card';
import { EventSearchFiltersComponent } from './components/event-search-filters/event-search-filters';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { TabFriendsComponent } from './components/tabs-profile/tab-friends/tab-friends.component';
import { EventsItemComponent } from './components/events-item/events-item';
import { SignInComponent } from './components/signIn/signIn.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { EventDetailEditComponent } from './components/event-detail-edit/event-detail-edit.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { CreateEventComponent } from "./components/create-event/create-event.component";
import { MapHeaderComponent } from './components/map/map-header/map-header.component';
import { TooltipModule } from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { ChipModule } from 'primeng/chip';
import { TagModule } from 'primeng/tag';
import { Message } from 'primeng/message';

@NgModule({
    imports: [
        FormsModule,
        CommonModule, // Keep CommonModule for shared modules
        RouterModule,
        PipeModule,
        DirectiveModule,
        ScrollingModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        SvgComponent,
        EventsItemComponent,
        CalendarModule,
        IconFieldModule,
        InputIconModule,
        InputTextModule,
        StyleClassModule,
        DropdownModule,
        DialogModule,
        PasswordModule,
        ScrollPanelModule,
        ToastModule,
        RippleModule,
        InputTextareaModule,
        TabViewModule,
        PaginatorModule,
        CheckboxModule,
        DynamicDialogModule,
        DatePickerModule,
        SelectModule,
        IftaLabelModule,
        OrderListModule,
        FieldsetModule,
        FloatLabelModule,
        ToggleSwitchModule,
        TextareaModule,
        AutoCompleteModule,
        RadioButtonModule,
        ProgressSpinnerModule,
        ChipModule,
        TagModule,
        Message,
		TooltipModule,
    ],
	declarations: [
		TabFriendsComponent,
		EventDetailComponent,
		HeaderComponent,
		DatePickerComponent,
		MapComponent,
		ClockComponent,
		CardEventComponent,
		ProfileCardComponent,
		EventSearchFiltersComponent,
		SignInComponent,
		CalendarComponent,
		EventDetailEditComponent,
		EventCardComponent,
		CreateEventComponent,
		FooterComponent,
		MapHeaderComponent
	],
	exports: [
		TabFriendsComponent,
		HeaderComponent,
		DatePickerComponent,
		MapComponent,
		ClockComponent,
		DirectiveModule,
		CardEventComponent,
		CommonModule,
		ProfileCardComponent,
		EventSearchFiltersComponent,
		EventDetailComponent,
		CalendarComponent,
		EventsItemComponent,
		SignInComponent,
		ScrollPanelModule,
		InputTextareaModule,
		CheckboxModule,
		DynamicDialogModule,
		DatePickerModule,
		SelectModule,
		IftaLabelModule,
		CreateEventComponent,
		AutoCompleteModule,
		MapHeaderComponent,
		RadioButtonModule,
		FooterComponent,
		ProgressSpinnerModule,
	],
})
export class SharedModule { }

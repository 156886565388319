import { Component, Input, OnInit } from '@angular/core';
import { MapHeaderService } from './map-header.service';


@Component({
	selector: 'app-map-header',
	templateUrl: './map-header.component.html',
	styleUrls: ['./map-header.component.scss']
})
export class MapHeaderComponent implements OnInit {

	@Input() withoutHeader!: boolean;

	constructor(public mapHeaderService: MapHeaderService) {
	}

	public ngOnInit() {
		this.mapHeaderService.setRadius(this.mapHeaderService.selectedRadius);
	}

	public zoomOut(): void {
		this.mapHeaderService.zoomStatus.next(false);
	}

	public zoomIn(): void {
		this.mapHeaderService.zoomStatus.next(true);
	}

	public visibleMap(event: boolean): void {
		this.mapHeaderService.visibleMap(false);
	}
}

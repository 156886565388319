import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class TypeImageService {


	public types = [
		{ id: 0, name: "", url: '/assets/images/type-image/0.jpg' },
		{ id: 1, name: "Общение", url: '/assets/images/type-image/1.jpg' },
		{ id: 2, name: "Спорт", url: '/assets/images/type-image/2.jpg' },
		{ id: 3, name: "Учёба", url: '/assets/images/type-image/3.jpg' },
		{ id: 4, name: "Поездки", url: '/assets/images/type-image/4.jpg' },
		{ id: 5, name: "Игры", url: '/assets/images/type-image/5.jpg' }
	];

	public curentTypeImage = new BehaviorSubject<any>(this.types[0]);


	public setTypeImg(id: number): any {
		const type = this.types.find(t => t.id === id);
		if (type) {
			this.curentTypeImage.next(type);
			return type;
		}

		return this.types[0]
	}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.style.scss'],
})
export class FooterComponent implements OnInit {

    public visible: boolean = false;

    constructor(private router: Router) {}

    ngOnInit(): void {
    }

    public openModal() {
    	this.visible = true;
    }
}

import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { EventsService } from '../../../core/services/api/events/events.service';
import { Router } from '@angular/router';
import { HttpStatusCodeApiService } from '../../../core/services/api/http-status-code-api.service';

@Component({
	selector: 'app-create-event',
	templateUrl: './create-event.component.html',
	styleUrls: ['./create-event.component.scss'],
})
export class CreateEventComponent implements OnInit {

	constructor(
		private eventService: EventsService,
		private router: Router,
		private httpStatusCodeApiService: HttpStatusCodeApiService
	) {}

	ngOnInit() {
	}

	public createEvent(event: any) {

		const createEvent = {
			title: event.title,
			description: event.description,
			type: event.type,
			status: 1,
			longitude: event.longitude,
			latitude: event.latitude,
			time_start: event.time_start,
			time_end: event.time_end,
			time_zone: event.time_zone
		};
		console.log(createEvent);
		this.eventService.addOrUpdateEvent(createEvent, 'put').subscribe({
			next: (val) => {
				this.httpStatusCodeApiService.setSuccess('Событие успешно создано.');
				this.router.navigate(['/profile']);
			},
			error:({error}) => {
				this.httpStatusCodeApiService.setError(error);
			}
		});
	}



}

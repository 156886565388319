import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MapHeaderService {

	public radius = [
		{ id: 1, radius: 5000 },
		{ id: 2, radius: 10000 },
		{ id: 3, radius: 25000 },
		{ id: 4, radius: 50000 },
	];

	public selectedRadius: number = this.radius[0].radius;

	public radiusSubject = new BehaviorSubject<number>(this.radius[0].radius);

	public radiusSubjectKm = new BehaviorSubject<number>(this.radius[0].radius / 1000);

	public isOpen = new BehaviorSubject<boolean>(false);
	public zoomStatus = new Subject<boolean>();

	constructor() {}

	public setRadius(radius: number): void {
		this.radiusSubject.next(radius);
		this.radiusSubjectKm.next(radius / 1000);
		this.selectedRadius = radius;
	}

	public visibleMap(status: boolean): void {
		this.isOpen.next(status);
	}
}

<form
	class="sign"
	[formGroup]="sigInForm"
	(submit)="submitForm()"
	autocomplete="on"
>
	<input
		pInputText
		formControlName="login"
		placeholder="Логин *"
		autocomplete="username"
	/>
	<div
		*ngIf="sigInForm.get('login')?.invalid && sigInForm.get('login')?.touched"
		class="error_mes"
	>
		<small>Логин должен начинаться с &#64;</small>
	</div>

	<p-password
		formControlName="password"
		[toggleMask]="true"
		[feedback]="false"
		placeholder="Пароль *"
		[ngClass]="{ 'ng-invalid ng-dirty': validFormData && sigInForm.valid }"
		autocomplete="current-password"
	></p-password>
	<small
		*ngIf="validFormData && sigInForm.valid"
		class="error_mes"
	>
		Неправильно указан логин и/или пароль
	</small>
	<br>

	<div class="registration">
		<img src="../../../../assets/images/qr.png" alt="" />
		<p>
			Ещё нет аккаунта? Отсканируйте QR-код, чтобы создать аккаунт при помощи
			<a href="https://t.me/porebric_bot" target="_blank">
				бота в телеграмме
			</a>
		</p>
	</div>
	<br>

	<p-button
		type="submit"
		[ngClass]="{disable_button: !sigInForm.valid}"
		[disabled]="!sigInForm.valid"
		(click)="submitForm()"
	>
		Войти
	</p-button>
</form>
